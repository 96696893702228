//Update Cancellation Policy here

import React from 'react';

function refundPolicy() {
  return (
    <div style={{ fontSize: '1rem', padding: '2rem' }}>
      <h1 style={{ textAlign: 'center' }}>Refunds & Cancellation</h1>
      
      <h2>REFUNDS</h2>
      <p>50% refund if cancelled booking more than 24 hours to the serivce time. 0% refund between 0-24 hours or in a case of no show. In case of any technical issue, full refund will be provided after investigation.</p>
      

      <h2>FOR REFUND OR CANCELLATION QUERIES</h2>
      <p>Contact:</p>
      <ul>
        <li>info@sportilabs.com</li>
      </ul>
    </div>
  );
}

export default refundPolicy;

