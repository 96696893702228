// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
/* import { getAnalytics } from "firebase/analytics"; */

//import firebase from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAGMoM6E3hyJlfnjtTqv8RT0d5AWFyZWmU",
  authDomain: "sportilabs.firebaseapp.com",
  projectId: "sportilabs",
  storageBucket: "sportilabs.appspot.com",
  messagingSenderId: "454708319710",
  appId: "1:454708319710:web:cf916413e13d24bb96394f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
/* const analytics = getAnalytics(app); */

//export const firestore = firebase.firestore();
export const db = getFirestore(app);